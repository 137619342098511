
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply bg-[#020215] text-text-primary font-sans overflow-x-hidden;
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  display: none;
}

button {
  transition: all 0.3s;
}